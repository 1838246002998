import { debug as factory } from "debug";
import * as moment from "moment";
import { addBreadcrumb, SeverityLevel } from "@sentry/angular";
import { localStore } from "@auvious/utils";
import { IEndpoint, IStream } from "@auvious/rtc";

export const createSentryLogger =
  (category: string) =>
  (message: any, level: SeverityLevel = "info") => {
    try {
      addBreadcrumb({
        category,
        message: JSON.stringify(message),
        level,
      });
    } catch {}
  };

export const debug = factory("auvious::core-ui");
export const debugError = factory("auvious::core-ui:error");
debugError.log = console.error.bind(console);

// export const debug = (...log) => { try { debugC(log); } catch (ex) { console.log(log); } };
// export const debugError = (...log) => { try { debugErrorC(log); } catch (ex) { console.error(log); } };

export function isDebugOn() {
  try {
    return localStorage.getItem("debug");
  } catch (ex) {
    return false;
  }
}

export function isDebugRtcOn() {
  try {
    return localStorage.getItem("debug.rtc");
  } catch (ex) {
    return false;
  }
}

export function isDebugBetaOn() {
  try {
    return localStorage.getItem("debug.beta");
  } catch (ex) {
    return false;
  }
}

export function roundTime(dateTime: moment.Moment): moment.Moment {
  if (dateTime) {
    const minute = dateTime.get("minutes");
    let nuMinute;
    if (minute % 10 !== 0) {
      if (minute < 55) {
        if (minute % 10 < 5) {
          nuMinute = Math.floor(minute / 10) * 10 + 5;
        } else {
          nuMinute = (Math.floor(minute / 10) + 1) * 10;
        }
        dateTime.set("minutes", nuMinute);
      } else {
        dateTime.set("minutes", 0);
        dateTime.add(1, "hour");
      }
    }
  }
  dateTime.set("seconds", 0);
  return dateTime;
}

export function copyTextToClipboard(text) {
  const textArea = document.createElement("textarea");

  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won't even render, not even a flash,
  // so some of these are just precautions. However in IE the element
  // is visible whilst the popup box asking the user for permission for
  // the web page to copy to the clipboard.
  //

  // Place in top-left corner of screen regardless of scroll position.
  textArea.style.position = "fixed";
  textArea.style.top = "0";
  textArea.style.left = "0";

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = "2em";
  textArea.style.height = "2em";

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = "0";

  // Clean up any borders.
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = "transparent";

  textArea.value = text;

  document.body.appendChild(textArea);

  textArea.select();

  let success = false;

  try {
    success = document.execCommand("copy");
    // const msg = successful ? 'successful' : 'unsuccessful';
    // debug('Copying text command was ' + msg);
  } catch (err) {
    // debug('Oops, unable to copy');
  }

  document.body.removeChild(textArea);
  return success;
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function milisecondsToDuration(milis: number): {
  hours?: string;
  minutes: string;
  seconds: string;
} {
  const totalSeconds = milis / 1000;
  if (!totalSeconds) {
    return { minutes: "00", seconds: "00" };
  }
  const seconds = pad(Math.floor(totalSeconds % 60));
  let minutes = pad(Math.floor(Number(totalSeconds / 60)));
  let hours: any = Math.floor(Math.floor(Number(totalSeconds / 60)) / 60);
  if (hours > 0) {
    hours = pad(hours);
    minutes = pad(Math.floor(Number(totalSeconds / 60) % 60));
  }
  return { hours: String(hours), minutes, seconds };
}

export function round(num: number): number {
  return Math.round(num * 100) / 100;
}

function pad(val: number) {
  const valString = val + "";
  return valString.length < 2 ? "0" + valString : valString;
}

export function toBase64(file): Promise<any> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function base64ToBlob(
  base64: string,
  mimeType: string = "image/png"
): Blob {
  // Split the base64 string into the data type and the actual data
  const byteString = atob(base64.split(",")[1]);

  // Create an array of 8-bit unsigned integers
  const byteNumbers = new Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }

  // Convert the array to a Uint8Array
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob from the Uint8Array
  return new Blob([byteArray], { type: mimeType });
}

export function extractErrorMessage(ex): string {
  const message =
    ex.response?.data?.error ||
    ex.response?.data?.message ||
    ex.body?.message ||
    ex.message ||
    ex;
  if (typeof message === "object") {
    return "Something unexpected happened...";
  }
  return message;
}

export function formatDuration(duration: string) {
  const obj = moment.duration(duration);
  return (
    (obj.hours() > 0 ? `${obj.hours()}h ` : "") +
    `${obj.minutes()}m ${obj.seconds()}s`
  );
}

const assertEnabled = localStore.getItem("auvious.assert") !== null;

export function assert<T>(value: T, message: string) {
  if (assertEnabled && value) {
    throw new Error(message);
  } else {
    return value;
  }
}

export function anonymizeIEndpoint(endpoint: IEndpoint) {
  try {
    return {
      ...endpoint,
      metadata: { ...endpoint.metadata, name: "?", avatarUrl: "?" },
    };
  } catch {
    return endpoint;
  }
}

export function anonymizeIStream(stream: IStream) {
  try {
    return {
      ...stream,
      originator: anonymizeIEndpoint(stream.originator),
    };
  } catch {
    return stream;
  }
}

export function base64UrlToBase64(base64Url) {
  return base64Url?.replace(/-/g, "+").replace(/_/g, "/");
}

export async function withTimeout<T>(
  promise: Promise<T>,
  ms: number
): Promise<T> {
  const timeout = new Promise<T>((_, reject) =>
    setTimeout(() => reject(new Error("timeout")), ms)
  );
  return Promise.race([promise, timeout]);
}

export function decodeBase64(base64) {
  const text = atob(base64);
  const length = text.length;
  const bytes = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    bytes[i] = text.charCodeAt(i);
  }
  const decoder = new TextDecoder(); // default is utf-8
  return decoder.decode(bytes);
}

export function findDuplicatesByProperty<T>(
  array: T[],
  property: keyof T
): T[] {
  const groupedObjects = array.reduce((acc, obj) => {
    const key = obj[property];
    // @ts-expect-error
    if (!acc[key]) {
      // @ts-expect-error
      acc[key] = [];
    }
    // @ts-expect-error
    acc[key].push(obj);
    return acc;
  }, {} as Record<string, T[]>);

  return Object.values(groupedObjects)
    .filter((group) => group.length > 1)
    .flat();
}
