import {
  Component,
  Input,
  Output,
  EventEmitter,
  signal,
  ChangeDetectionStrategy,
  ViewChildren,
  QueryList,
} from "@angular/core";
import {
  findDuplicatesByProperty,
  IMetadata,
  ISelectOption,
} from "../../../../core-ui";
import { MetadataBuilderPairComponent } from "./pair/pair.component";

@Component({
  selector: "av-metadata-builder",
  templateUrl: "./metadata-builder.component.html",
  styleUrls: ["./metadata-builder.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetadataBuilderComponent {
  @Input() meta: IMetadata<any>[];
  @Input() types: ISelectOption[];

  @Output() updated = new EventEmitter<IMetadata<any>[]>();

  @ViewChildren("pair") pairsRef: QueryList<MetadataBuilderPairComponent>;

  isDuplicateKey = signal(false);
  isFieldMissing = signal(false);

  itemUpdated(item: IMetadata<any>, index) {
    const duplicates = findDuplicatesByProperty(this.meta, "key");
    this.isDuplicateKey.set(duplicates.length > 0);
    this.isFieldMissing.set(false);
    this.updated.emit(this.meta.map((m, idx) => (idx === index ? item : m)));
  }

  itemRemoved(item: IMetadata<any>, index) {
    this.isFieldMissing.set(false);
    this.updated.emit(this.meta.filter((m, idx) => idx !== index));
  }

  get valid() {
    let fieldsValid = true;
    this.pairsRef.forEach((item) => (fieldsValid = fieldsValid && item.valid));
    this.isFieldMissing.set(!fieldsValid);
    return !this.isDuplicateKey() && fieldsValid;
  }
}
