import { NgModule, ErrorHandler, Injector, CSP_NONCE } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { APP_BASE_HREF } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoreUiModule } from "../core-ui";
import { SharedModule } from "../modules/shared/shared.module";
import { AppRoutingModule } from "./router/app.routing";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { QRCodeModule } from "angularx-qrcode";
import { CookieService } from "ngx-cookie-service";
import { GoogleTagManagerModule } from "../modules/google-tag-manager";
import { AppComponent } from "./app.component";

import {
  WelcomePageComponent,
  ConferencePageComponent,
  ThankYouComponent,
  ApplicationsPageComponent,
  SetupPageComponent,
  ErrorPageComponent,
  ExportPageComponent,
  WaitPageComponent,
  CompositionsPageComponent,
  AppointmentPageComponent,
  CobrowsePageComponent,
  RedirectPageComponent,
} from "./pages";

import {
  QRComponent,
  CallbackComponent,
  CobrowseLauncherComponent,
  UserMenuComponent,
  DisclaimerComponent,
  ConversationLauncherComponent,
  LocalePickerComponent,
  ShareLinkComponent,
  ShareLinkEmailComponent,
  ShareLinkSmsComponent,
  ShareLinkChatComponent,
  ShareLinkQrComponent,
  ShareLinkScheduleComponent,
  ShareLinkWidgetComponent,
  ShareLinkSMSNumberComponent,
  ShareLinkEmailAddressComponent,
} from "./components";

import { PureCloudService } from "./services/purecloud.service";
import { QueueService } from "./services/queue.service";
import { InvitationService } from "./services/invitation.service";
import {
  GenericErrorHandler,
  provideErrorHandler,
  SentryErrorHandler,
} from "./services/error-handlers.service";
import { QRService } from "./services/qr.service";
import { InteractionService } from "./services/interaction.service";
import { ApplicationFactory } from "./factories/ApplicationFactory";
import { IntegrationService } from "./services/integration.service";
import { ConversationService } from "./services/conversation.service";
import { LocaleService } from "./services/locale.service";
import { MenuService } from "./services/menu.service";
import { NiceService } from "./services/nice.service";
import { VerintService } from "./services/verint.service";
import { DigitalConnectService } from "./services/digital.connect.service";

@NgModule({
  imports: [
    SharedModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    QRCodeModule,
    CoreUiModule.forRoot({
      errorHandler: {
        provide: ErrorHandler,
        useFactory: provideErrorHandler,
        deps: [Injector],
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient],
      },
    }),
    GoogleTagManagerModule.forRoot({
      id: "GTM-KCZ98LB",
    }),
  ],
  declarations: [
    AppComponent,
    ConferencePageComponent,
    WelcomePageComponent,
    ThankYouComponent,
    SetupPageComponent,
    ErrorPageComponent,
    QRComponent,
    CallbackComponent,
    ExportPageComponent,
    WaitPageComponent,
    ApplicationsPageComponent,
    CobrowseLauncherComponent,
    UserMenuComponent,
    CompositionsPageComponent,
    DisclaimerComponent,
    AppointmentPageComponent,
    ConversationLauncherComponent,
    CobrowsePageComponent,
    RedirectPageComponent,
    LocalePickerComponent,
    ShareLinkComponent,
    ShareLinkEmailComponent,
    ShareLinkSmsComponent,
    ShareLinkChatComponent,
    ShareLinkQrComponent,
    ShareLinkScheduleComponent,
    ShareLinkWidgetComponent,
    ShareLinkSMSNumberComponent,
    ShareLinkEmailAddressComponent,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: "/" },
    {
      provide: "googleTagManagerCSPNonce",
      useValue: (
        document.querySelector('meta[name="CSP_NONCE"]') as HTMLMetaElement
      )?.content,
    },
    {
      provide: ErrorHandler,
      useFactory: provideErrorHandler,
      deps: [Injector],
    },
    {
      provide: CSP_NONCE,
      useValue: (
        document.querySelector('meta[name="CSP_NONCE"]') as HTMLMetaElement
      )?.content,
    },
    CookieService,
    ApplicationFactory,
    InteractionService,
    PureCloudService,
    NiceService,
    VerintService,
    DigitalConnectService,
    SentryErrorHandler,
    GenericErrorHandler,
    QRService,
    QueueService,
    InvitationService,
    IntegrationService,
    ConversationService,
    LocaleService,
    MenuService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
