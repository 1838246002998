<av-metadata-builder-pair
  #pair
  *ngFor="let pair of meta; index as i"
  [item]="pair"
  [types]="types"
  (updated)="itemUpdated($event, i)"
  (removed)="itemRemoved($event, i)"
>
  <ng-content select="[metadataHelp]"></ng-content>
</av-metadata-builder-pair>
<div translate class="form-error" *ngIf="isDuplicateKey()">
  Duplicate key detected
</div>
<div translate class="form-error" *ngIf="isFieldMissing()">
  All fields are required
</div>
<div>
  <ng-content></ng-content>
</div>
