import { Injectable, Injector } from "@angular/core";

import { CustomerApplication } from "../models/applications/CustomerApplication";
import { GenesysCloudApplication } from "../models/applications/GenesysCloudApplication";
import { OpenIDConnectApplication } from "../models/applications/OpenIDConnectApplication";

import { ApplicationTypeEnum } from "../../core-ui";
import {
  IApplication,
  IApplicationDTO,
  INiceApplicationConfig,
  IGenesysApplicationConfig,
  ITalkdeskApplicationConfig,
} from "../../core-ui/models/IApplication";
import { ApplicationService, AppConfigService } from "../../core-ui/services";
import { TestApplication } from "../models/applications/TestApplication";
import { ActivatedRouteSnapshot } from "@angular/router";
import { PARAM_CXEXCHANGE_ISS, PARAM_GENESYS_ENV } from "../app.enums";

import { AuthState } from "../../core-ui/models/AuthState";
import { TalkdeskApplication } from "../models/applications/TalkdeskApplication";
import { NiceApplication } from "../models/applications/NiceApplication";
import { VerintApplication } from "../models/applications/VerintApplication";

@Injectable()
export class ApplicationFactory {
  constructor(
    private applicationService: ApplicationService,
    private config: AppConfigService,
    private injector: Injector
  ) {}

  async createAgentApplication(
    applicationId: string,
    route?: ActivatedRouteSnapshot
  ): Promise<IApplication> {
    let appDto: IApplicationDTO;
    try {
      if (applicationId) {
        appDto = await this.applicationService.get(applicationId);
      } else {
        // probably we are in a setup page of an integration and we don't have yet an application.
        // check other query params to determine type
        appDto = this.discoverDefaultApplicationDto(route);
      }
    } catch (error) {
      if (
        !!route &&
        route.queryParamMap.has("agentUser") &&
        route.queryParamMap.has("agentPass")
      ) {
        appDto = this.fallbackAppDto();
      } else {
        throw error;
      }
    }

    return this.createAgentApplicationFromDto(appDto);
  }

  async createCustomerApplication(
    applicationId: string
  ): Promise<IApplication> {
    let appDto: IApplicationDTO;

    try {
      appDto = await this.applicationService.get(applicationId);
    } catch (error) {
      appDto = this.fallbackAppDto();
    }

    return new CustomerApplication(
      appDto,
      this.injector,
      this.config.clientIdCustomer
    );
  }

  createAgentApplicationFromDto(appDto: IApplicationDTO): IApplication {
    switch (appDto.type) {
      case ApplicationTypeEnum.GenesysCloud:
        return this.createGenesysAgentApplication(appDto);
      case ApplicationTypeEnum.StandaloneOpenID:
        return appDto.id === "verint-poc"
          ? this.createVerintAgentApplication(appDto)
          : this.createOpenIDConnectAgentApplication(appDto);
      case ApplicationTypeEnum.TalkdeskOpenID:
        return this.createTalkdeskAgentApplication(appDto);
      case ApplicationTypeEnum.NiceOpenID:
        return this.createNiceAgentApplication(appDto);
      case ApplicationTypeEnum.Test:
        return this.createTestApplication(appDto);
      case ApplicationTypeEnum.verintOpenID:
        return this.createVerintAgentApplication(appDto);
    }
  }

  private createTestApplication(appDto: IApplicationDTO): IApplication {
    return new TestApplication(
      appDto,
      this.injector,
      this.config.clientIdTestAgent
    );
  }

  private createGenesysAgentApplication(appDto: IApplicationDTO): IApplication {
    return new GenesysCloudApplication(appDto, this.injector);
  }

  private createOpenIDConnectAgentApplication(
    appDto: IApplicationDTO
  ): IApplication {
    return new OpenIDConnectApplication(appDto, this.injector);
  }

  private createTalkdeskAgentApplication(
    appDto: IApplicationDTO
  ): IApplication {
    return new TalkdeskApplication(appDto, this.injector);
  }

  private createNiceAgentApplication(appDto: IApplicationDTO): IApplication {
    return new NiceApplication(appDto, this.injector);
  }

  private createVerintAgentApplication(appDto: IApplicationDTO): IApplication {
    return new VerintApplication(appDto, this.injector);
  }

  private discoverDefaultApplicationDto(
    route: ActivatedRouteSnapshot
  ): IApplicationDTO {
    if (route.queryParamMap.has(PARAM_GENESYS_ENV)) {
      return this.defaultGenesysDto(route.queryParamMap.get(PARAM_GENESYS_ENV));
    }
    if (route.queryParamMap.get(PARAM_CXEXCHANGE_ISS)?.includes("cxexchange")) {
      return this.defaultNiceDto();
    } else if (route.queryParamMap.has("state")) {
      const state = AuthState.fromSerializedState(
        route.queryParamMap.get("state")
      );
      if (!!state.pcEnvironment) {
        return this.defaultGenesysDto(state.pcEnvironment);
      } else if (state.iss?.includes("cxexchange")) {
        return this.defaultNiceDto();
      }
    }
    return this.fallbackAppDto();
  }

  private fallbackAppDto(): IApplicationDTO {
    return {
      id: "test",
      organizationId: "test",
      createdAt: "1970-01-01T00:00:00Z",
      version: 0,
      type: ApplicationTypeEnum.Test,
      config: {},
    };
  }

  private defaultGenesysDto(pcEnvironment: string): IApplicationDTO {
    return {
      id: null,
      organizationId: null,
      createdAt: new Date().toISOString(),
      version: 0,
      type: ApplicationTypeEnum.GenesysCloud,
      config: {
        organizationId: null,
        pcEnvironment,
        integrationId: null,
        serviceParameters: {},
        agentParameters: {},
        customerParameters: {},
        publicParameters: {},
      } as IGenesysApplicationConfig,
    };
  }

  private defaultNiceDto(): IApplicationDTO {
    return {
      id: null,
      organizationId: null,
      createdAt: new Date().toISOString(),
      version: 0,
      type: ApplicationTypeEnum.NiceOpenID,
      config: {
        serviceParameters: {},
        agentParameters: {},
        customerParameters: {},
        publicParameters: {},
        openidParameters: NiceApplication.defaultOpenidParams(),
      } as INiceApplicationConfig,
    };
  }
}
