import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiResource } from "@auvious/common";
import { sessionStore } from "@auvious/utils";
import { ContactStatus } from "@nice-devone/nice-cxone-chat-web-sdk";
import { firstValueFrom } from "rxjs/internal/firstValueFrom";
import {
  AppConfigService,
  AuviousRtcService,
  ConversationChannelEnum,
  ConversationOriginEnum,
  ConversationTypeEnum,
  decodeBase64,
  IConversationEventHandlers,
  OriginModeEnum,
  UserService,
} from "../../core-ui";
import {
  KEY_ROOM_NAME,
  TAG_CUSTOMER_TRANSFER_ROOM,
  WIDGET_CONVERSATION_TYPE,
  WIDGET_REFERRER_ID,
} from "../app.enums";
import { debugError } from "../app.utils";
import { IConversationInfo } from "../models";
import { INiceTokenResponse } from "../models/NiceTokenResponse";
import {
  DigitalUserDetails,
  OutboundMessage,
} from "../models/strategies/nice/req.types";
import { Case, Contact } from "../models/strategies/nice/types";
import {
  NiceWSEvents,
  PushUpdateEventType,
} from "../models/strategies/nice/wss.types";
import { GenericErrorHandler } from "./error-handlers.service";
import { IntegrationService } from "./integration.service";

enum PollState {
  idle,
  polling,
  done,
}

const CUSTOM_FIELDS_KEY = "auvious";
// const CUSTOMER_CUSTOM_FIELDS_KEY = "auvious-customer-metadata";

@Injectable()
export class VerintService {
  static clientId = "default";

  public dfoAgentId: number;
  public fullName: string;
  public tenantId: string;

  // TODO: should this be in app config?
  // private clusterApi =
  //   "https://api-{cluster}.nice-incontact.com/incontactapi/services/v29.0";
  private areaApi: string; // = "https://api-na1.niceincontact.com";
  private securityApi: ApiResource;
  private proxyApi: ApiResource;
  private sessionID: string;

  private refreshToken: string;

  private pollState = PollState.idle;
  private pollingTimeout = 60;
  private lastPoll = 0;
  private pollThrottle = 5000;

  private handlers: IConversationEventHandlers = {};
  private initTask = Promise.resolve();

  private contacts: Contact[] = [];

  constructor(
    private config: AppConfigService,
    private errorHandler: GenericErrorHandler,
    private httpClient: HttpClient,
    private rtc: AuviousRtcService,
    private user: UserService,
    private integration: IntegrationService
  ) {
    // console.warn(this.config);
  }

  private get timers() {
    return this.rtc.getAuviousCommonClient().getTimers();
  }

  public async init(token: INiceTokenResponse): Promise<void> {
    console.log("verint token", token);

    this.proxyApi = this.rtc
      .getAuviousCommonClient()
      .apiResourceFactory("api/cx/verint");
  }

  public registerConversationEventHandlers(
    handlers: IConversationEventHandlers
  ) {
    this.handlers = handlers || {};
  }

  public async discoverActiveConversation(): Promise<IConversationInfo> {
    return {
      communicationId: "default",
      conversationId: "default",
      customerId: "default",
      agentId: "default",
      room: "",
      origin: ConversationOriginEnum.WIDGET,
      legacy: false,
      type: ConversationTypeEnum.chat,
      channel: ConversationChannelEnum.verintLiveChat,
    };
  }

  public async retrieveInteractionData(id: string) {}

  public async postOutboundMessage(
    caseId: string,
    text: string
  ): Promise<object> {
    if (!text.includes("auvious")) text += "#auvious";

    await this.proxyApi.create(
      {
        value: text,
      },
      { urlPostfix: "context-update" }
    );

    return undefined;
  }

  private waitFor(timeout: number) {
    return new Promise((resolve) => this.timers.setTimeout(resolve, timeout));
  }
}
