import { HttpClient } from "@angular/common/http";
import { Injector } from "@angular/core";

import {
  BrowserNotificationStrategy,
  NoConversationStrategy,
  NoQueueStrategy,
  VerintIntegrationStrategy,
  VerintInteractionStrategy,
  NoAnalyticsStrategy,
  StandaloneInvitationStrategy,
} from "../strategies";

import { sessionStore } from "@auvious/utils";
import { firstValueFrom, of } from "rxjs";
import {
  ApplicationTypeEnum,
  KEY_CX_OPENID_CONFIG,
  MediaFilterTypeEnum,
  OAUTH2_CODE_GRAND_REDIRECT_URI,
} from "../../../core-ui/core-ui.enums";
import {
  IApplication,
  IApplicationDTO,
  IOpenIDConnectApplicationConfig,
  IVerintApplicationConfig,
} from "../../../core-ui/models/IApplication";
import {
  IAnalyticsStrategy,
  IAuthenticationStrategy,
  IConversationStrategy,
  IIntegrationStrategy,
  IInteractionStrategy,
  IInvitationStrategy,
  INotificationStrategy,
  IQueueStrategy,
  OAuth2CodeGrantAuthenticationStrategy,
} from "../../../core-ui/models/strategies";
import {
  AppConfigService,
  AuviousRtcService,
  GenericErrorHandler,
  ProtectedTicketService,
  UserService,
} from "../../../core-ui/services";
import { INiceTokenResponse } from "../NiceTokenResponse";
import { NiceInteractionStrategy } from "../strategies/nice/NiceInteractionStrategy";
import { NiceIntegrationStrategy } from "../strategies/nice/NiceIntegrationStrategy";
import { VerintService } from "../../services/verint.service";

export interface IOpenIDConfig {
  // issuer: "https://cxone.niceincontact.com";
  // authorization_endpoint: "https://cxone.niceincontact.com/auth/authorize";
  // token_endpoint: "https://cxone.niceincontact.com/auth/token";
  // userinfo_endpoint: "https://cxone.niceincontact.com/auth/userinfo";
  // jwks_uri: "https://cxone.niceincontact.com/auth/jwks";
  // token_endpoint_auth_methods_supported: [
  //   "client_secret_basic",
  //   "client_secret_post"
  // ];
  // token_endpoint_auth_signing_alg_values_supported: ["RS256"];
  // end_session_endpoint: "https://cxone.niceincontact.com/auth/authorize/logout";
  // scopes_supported: ["openid"];
  // response_types_supported: ["code"];
  // subject_types_supported: ["public"];
  // id_token_signing_alg_values_supported: ["RS256"];
  // request_object_signing_alg_values_supported: ["none"];
  // display_values_supported: ["page", "popup"];
  // claim_types_supported: ["normal"];
  // code_challenge_methods_supported: ["S256"];
}

export class VerintApplication implements IApplication {
  private mAuthenticationStrategy: IAuthenticationStrategy;
  private mInteractionStrategy: IInteractionStrategy;
  private mAnalyticsStrategy: IAnalyticsStrategy;
  private mQueueStrategy: IQueueStrategy;
  private mTicketStrategy: IInvitationStrategy;
  private mNotificationStrategy: INotificationStrategy;
  private mIntegrationStrategy: IIntegrationStrategy;
  private mConversationStrategy: IConversationStrategy;

  private appConfigService: AppConfigService;
  private userService: UserService;
  private verintService: VerintService;

  constructor(private dto: IApplicationDTO, private injector: Injector) {
    this.appConfigService = injector.get(AppConfigService);
    this.appConfigService.setApplicationConfig(dto.config);
    this.userService = injector.get(UserService);
    this.verintService = injector.get(VerintService);

    this.mAuthenticationStrategy = new OAuth2CodeGrantAuthenticationStrategy(
      this.injector.get(AuviousRtcService),
      this.injector.get(HttpClient),
      {
        clientId: "openid",
        redirectUri: window.location.origin + OAUTH2_CODE_GRAND_REDIRECT_URI,
        authProvider: async () => {
          const config = this.dto.config as IOpenIDConnectApplicationConfig;
          return {
            clientId: config.openidParameters["client-id"],
            authorizationUrl: config.openidParameters["authorization-url"],
            authorizationUrlExtraParameters:
              config.openidParameters["authorization-url-extra-parameters"],
            scope: config.openidParameters.scope || "openid",
          };
        },
        hydratePasswordParams: (params) => {
          params.set("application_id", this.getId());
        },
        onTokenResponse: async (response: INiceTokenResponse) => {
          await this.verintService.init(response);
        },
        config: dto.config,
      }
    );

    this.mAnalyticsStrategy = new NoAnalyticsStrategy();
    this.mQueueStrategy = new NoQueueStrategy();
    this.mTicketStrategy = new StandaloneInvitationStrategy(
      this.getId(),
      this.getConfig()?.publicParameters
    );
    this.mNotificationStrategy = BrowserNotificationStrategy.singleton();
    this.mIntegrationStrategy = new VerintIntegrationStrategy();
    this.mConversationStrategy = new NoConversationStrategy();
    this.mInteractionStrategy = new VerintInteractionStrategy(
      injector.get(ProtectedTicketService),
      injector.get(GenericErrorHandler),
      this.verintService
    );
  }

  static defaultOpenidParams() {
    return {
      client_id: VerintService.clientId,
      "authorization-url":
        "https://em14.verint.live/oidc-token-service/default/token",
    };
  }

  supportsInteractions(): boolean {
    return false;
  }

  supportsInteractionMetrics(): boolean {
    return false;
  }

  getId(): string {
    return this.dto.id;
  }

  registerLifecycleEventHandlers() {}

  getDto(): IApplicationDTO {
    return this.dto;
  }

  getType(): ApplicationTypeEnum {
    return ApplicationTypeEnum.StandaloneOpenID;
  }

  getOrganizationId(): string {
    return this.dto.organizationId;
  }

  getConfig(): IOpenIDConnectApplicationConfig {
    return this.dto.config;
  }

  getCreatedAt(): Date {
    return new Date(this.dto.createdAt);
  }

  getVersion(): number {
    return this.dto.version;
  }

  getFilterBackgrounds() {
    const url = "core-ui/assets/media-filter-backgrounds";
    return [
      {
        id: "open-id-1",
        type: MediaFilterTypeEnum.backgroundImage,
        url: of(`${url}/living-room-1.jpg`),
      },
      {
        id: "open-id-2",
        type: MediaFilterTypeEnum.backgroundImage,
        url: of(`${url}/living-room-4.jpg`),
      },
      {
        id: "open-id-3",
        type: MediaFilterTypeEnum.backgroundImage,
        url: of(`${url}/office-1.jpg`),
      },
    ];
  }

  canCallWithoutActiveInteraction(): boolean {
    return true; // !this.getConfig().agentParameters?.videoCallRequiresInteraction;
  }

  isReady(): boolean {
    return true;
  }

  authenticationStrategy(): IAuthenticationStrategy {
    return this.mAuthenticationStrategy;
  }

  interactionStrategy(): IInteractionStrategy {
    return this.mInteractionStrategy;
  }

  queueStrategy(): IQueueStrategy {
    return this.mQueueStrategy;
  }

  analyticsStrategy(): IAnalyticsStrategy {
    return this.mAnalyticsStrategy;
  }

  ticketStrategy(): IInvitationStrategy {
    return this.mTicketStrategy;
  }

  notificationStrategy(): INotificationStrategy {
    return this.mNotificationStrategy;
  }

  integrationStrategy(): IIntegrationStrategy {
    return this.mIntegrationStrategy;
  }

  conversationStrategy(): IConversationStrategy {
    return this.mConversationStrategy;
  }
}
