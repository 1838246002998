import { IInteractionStrategy } from "../../../../core-ui/models/strategies";
import {
  ConversationChannelEnum,
  ConversationTypeEnum,
  EndpointTypeEnum,
  GenericErrorHandler,
  IConversationEventHandlers,
  IInteraction,
  IInteractionDetails,
  IInteractionMessageOptions,
  ProtectedTicketService,
} from "../../../../core-ui";
import { NiceInteraction } from "../../NiceInteraction";
import { NiceInteractionDetails } from "../../NiceInteractionDetails";
import { WIDGET_COBROWSE_REQUEST_PREFIX } from "@auvious/integrations";
import { PARAM_CALL_TYPE } from "../../../../app/app.enums";
import { VerintService } from "../../../services/verint.service";
import { VerintInteraction } from "../../VerintInteraction";

export class VerintInteractionStrategy implements IInteractionStrategy {
  private interaction: IInteraction = null;

  constructor(
    private ticketService: ProtectedTicketService,
    private errorHandler: GenericErrorHandler,
    private verintService: VerintService
  ) {}

  isActiveInteractionAvailable(): boolean {
    return !!this.interaction;
  }

  getActiveInteraction(): IInteraction {
    return this.interaction;
  }

  setActiveInteraction(interaction: IInteraction) {
    this.interaction = interaction;
  }

  clearActiveInteraction() {
    this.interaction = undefined;
  }

  async discoverActiveInteraction() {
    try {
      const interaction = await this.verintService.discoverActiveConversation();

      if (interaction)
        this.setActiveInteraction(new VerintInteraction(interaction));

      return this.interaction;
    } catch (ex) {
      return null;
    }
  }

  async retrieveInteractionData(id: string, channel?: ConversationChannelEnum) {
    return null;
    // const info = await this.verintService.retrieveInteractionData(id);
    // return info ? new VerintInteraction(info) : null;
  }

  registerConversationEventHandlers(handlers: IConversationEventHandlers) {
    this.verintService.registerConversationEventHandlers(handlers);
  }

  prepareInteractionScheduleRequest() {
    return null;
  }

  scheduleInteraction() {
    return null;
  }

  createEmailInteraction() {
    return null;
  }

  createSMSInteraction() {
    return null;
  }

  invite(
    toConversationType: ConversationTypeEnum,
    interaction: NiceInteraction,
    ticket: string,
    options?: IInteractionMessageOptions
  ) {
    let message: string;
    switch (toConversationType) {
      case ConversationTypeEnum.cobrowse:
        // let permission;
        // switch (options?.endpointType) {
        //   case EndpointTypeEnum.displayCapture:
        //     permission = "display-capture";
        //     break;

        //   case EndpointTypeEnum.coBrowse:
        //   default:
        //     permission = "view";
        //     break;
        // }
        // message = [WIDGET_COBROWSE_REQUEST_PREFIX, permission, ticket].join(
        //   ":"
        // );
        break;
      case ConversationTypeEnum.videoCall:
      case ConversationTypeEnum.voiceCall:
      case ConversationTypeEnum.chat:
        let link: URL | string = "";
        const customerLink = this.ticketService.getTicketWithDomain(ticket);

        try {
          link = new URL(customerLink);
          link.searchParams.set(PARAM_CALL_TYPE, interaction.getType());
        } catch (ex) {
          // this.errorHandler.handleError(ex.message + " -> " + customerLink);
          link = customerLink.includes("?")
            ? `${link}&${PARAM_CALL_TYPE}=${interaction.getType()}`
            : `${link}?${PARAM_CALL_TYPE}=${interaction.getType()}`;
        }

        message = link.toString();
        break;
    }

    return this.postMessageToInteraction(interaction, message, options);
  }

  async postMessageToInteraction(
    interaction: NiceInteraction,
    message: string,
    options: IInteractionMessageOptions
  ) {
    this.verintService.postOutboundMessage(interaction.getId(), message);
  }

  getChatTranscript() {
    return null;
  }

  async getInteractionDetails(caseId: string): Promise<IInteractionDetails> {
    return null;
  }

  getInvitation(
    forConversationType: ConversationTypeEnum,
    ticket: string,
    options?: IInteractionMessageOptions
  ): string {
    return null;
  }
}
