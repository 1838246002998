import { Duration } from "moment";
import {
  AppointmentChannelEnum,
  AppointmentNotificationTypeEnum,
} from "../core-ui.enums";
import { ChatModeEnum } from "@auvious/integrations";

export interface IAppointmentQueryParams {
  organizerId?: string[];
  duration?: Duration[];
  range?: [Date, Date];
  pageSize?: number;
  pageNumber?: number;
  sortOrder?: "asc" | "desc";
  sortField?: string;
}

export interface IAppointmentParticipant {
  id: string;
  type: "CUSTOMER" | "AGENT";
  state?: "UNSET" | "JOINED" | "LEFT" | "WAITING";
}

export interface IAppointmentInteraction<T extends IAppointmentRouting> {
  customFields?: { [key: string]: string };
  routing: T;
  state?: AppointmentState;
}

export enum AppointmentRoutingTypeEnum {
  GENESYS_CLOUD_FLOW = "GENESYS_CLOUD_FLOW",
  GENESYS_CLOUD_QUEUE = "GENESYS_CLOUD_QUEUE",
  TALKDESK_DIGITAL_CONNECT = "TALKDESK_DIGITAL_CONNECT",
}

export type AppointmentViewState =
  | "PENDING" // appointment pending
  | "EXPIRED" // appointment is pending AND end time passed
  | "MISSED" // appointment in progress AND customer left AND agent not joined AND end time passed
  | "ONGOING" // appointment in progress AND now is between start and end
  | "OVERRUNING" // appointment in progress AND end time passed AND customer not left
  | "COMPLETED"; // appointment complete

export type AppointmentState = "IN_PROGRESS" | "PENDING" | "COMPLETE";

export enum AppointmentParticipantStateEnum {
  WAITING = "WAITING",
  JOINED = "JOINED",
  LEFT = "LEFT",
}

export type AppointmentEventType =
  | "APScheduledEvent"
  | "APRescheduledEvent"
  | "APReassignedEvent"
  | "APUpdatedEvent"
  | "APInProgressEvent"
  | "APCompletedEvent"
  | "APExpiredEvent"
  | "APAgentJoinedEvent"
  | "APAgentLeftEvent"
  | "APCustomerWaitingEvent"
  | "APCustomerJoinedEvent"
  | "APCustomerLeftEvent"
  | "APDeletedEvent";

// export enum AppointmentEventTypeEnum {
//   APScheduledEvent = "APScheduledEvent",
//   APRescheduledEvent = "APRescheduledEvent",
//   APReassignedEvent = "APReassignedEvent",
//   APUpdatedEvent = "APUpdatedEvent",
//   APInProgressEvent = "APInProgressEvent",
//   APCompletedEvent = "APCompletedEvent",
//   APExpiredEvent = "APExpiredEvent",
//   APAgentJoinedEvent = "APAgentJoinedEvent",
//   APAgentLeftEvent = "APAgentLeftEvent",
//   APCustomerWaitingEvent = "APCustomerWaitingEvent",
//   APCustomerJoinedEvent = "APCustomerJoinedEvent",
//   APCustomerLeftEvent = "APCustomerLeftEvent",
//   APDeletedEvent = "APDeletedEvent",
// }

export interface IAppointmentRouting {
  type?: AppointmentRoutingTypeEnum;
  properties?: { [key: string]: string };
}

export interface IAppointmentRoutingGenesysCloudFlow
  extends IAppointmentRouting {
  properties: {
    // wiget deployment id with a custom flow to route calls to specific agents
    deploymentId?: string;
    pcEnvironment?: string;
    chatMode?: ChatModeEnum;
    // | "genesys-api"
    // | "genesys-cloud"
    // | "genesys-premise"
    // | "genesys-web-messaging"
    // | "talkdesk-digital-connect";
    //---
    //returned only after appointment is activated
    //Agent User id that will receive the chat interaction. Must map it to architect flow
    agentId?: string;
    ticketId?: string;
    conferenceId?: string;
  };
}

export interface IAppointmentRoutingTalkdeskDC extends IAppointmentRouting {
  properties: {
    touchpointId: string;
    chatMode?: ChatModeEnum;
    //---
    // returned only after appointment is activated
    ticketId?: string;
    conferenceId?: string;
  };
}

export interface IAppointmentRoutingGenesysCloudQueue
  extends IAppointmentRouting {
  properties: {
    deploymentId?: string;
    queueName: string;
    pcEnvironment?: string;
    chatMode?: string;
    //---
    //returned only after appointment is activated
    conferenceId?: string;
    ticketId?: string;
  };
}

export interface IContactBase {
  contact?: {
    primaryEmail: string;
    primaryPhone: string;
  };
  name?: {
    firstName: string;
    lastName: string;
  };
  username?: string;
}

export interface IContact extends IContactBase {
  id: string;
  organizationId: string;
  extra?: {
    height?: string;
    personality?: string;
    weight?: string;
    "frequently-used-words"?: string[];
  };
}

export interface IAppointmentContact extends IContactBase {
  metadata?: any;
}

export const DEFAULT_APPOINTMENT_CONTACT: IAppointmentContact = {
  contact: {
    primaryEmail: undefined,
    primaryPhone: undefined,
  },
  metadata: {},
  name: {
    firstName: undefined,
    lastName: undefined,
  },
  username: undefined,
};

export interface IScheduledAppointmentContact extends IAppointmentContact {
  id: string;
  type: "CUSTOMER" | "AGENT";
}

interface IAppointmentOptions<T extends IAppointmentRouting> {
  title?: string;
  notes?: string;
  metadata?: IAppointmentMetadata;
  notifications?: IAppointmentNotification[];
  interaction?: IAppointmentInteraction<T>;
  notificationHeaders?: { [key: string]: string };
}

interface IAppointmentBase<T extends IAppointmentRouting>
  extends IAppointmentOptions<T> {
  duration: string;
  start: Date;
  timezone: string;
  applicationId: string;
}

export interface IAppointmentMetadata {
  timezone?: string;
  //@deprecated. use interaction.routing.properties.chatMode instead
  chatMode?: string;
}

export interface IAppointment<T extends IAppointmentRouting>
  extends IAppointmentBase<T> {
  id?: string;
  end: Date;
  organizer: IAppointmentParticipant;
  participantIds: IAppointmentParticipant[];
}

export interface IScheduleAppointmentRequest
  extends IAppointmentBase<IAppointmentRouting> {
  customer?: IAppointmentContact;
  locale?: string;
}

export interface IScheduleAppointmentOptions
  extends IAppointmentOptions<IAppointmentRouting> {
  id: string;
}

export interface IAppointmentNotification {
  channel: AppointmentChannelEnum;
  type: AppointmentNotificationTypeEnum;
  period?: string;
  message?: string; // support previous model
  textBody?: string;
  htmlBody?: string;
  subject?: string;
  enabled?: boolean;
  properties?: string[];
  id: string;
}

export interface IAppointmentFormat {
  example: string;
  format: string;
}

export interface IAppointmentLocale {
  locale: string;
  description: string;
}
