<form #f="ngForm" class="fx-row fx-align-start fx-flex-1 fx-gap-10">
  <div class="form-item fxFlex-1">
    <label translate>Key</label>
    <input
      required
      avInput
      #keyRef="ngModel"
      name="key"
      [(ngModel)]="item.key"
      (ngModelChange)="update()"
      type="text"
    />
    <div
      translate
      class="form-error"
      *ngIf="keyRef.touched && keyRef.hasError('required')"
    >
      Required
    </div>
  </div>

  <div class="form-item fx-flex-1">
    <label translate>Value</label>
    <input
      required
      avInput
      #valueRef="ngModel"
      name="value"
      [(ngModel)]="item.value"
      (ngModelChange)="update()"
      type="text"
    />
    <div
      translate
      class="form-error"
      *ngIf="valueRef.touched && valueRef.hasError('required')"
    >
      Required
    </div>
  </div>

  <ng-container *ngIf="hasType">
    <div class="form-item fx-flex-2">
      <!-- <label class="fx-row fx-align-end fx-gap-10"
        ><span translate>Type</span>
        <ng-content></ng-content>
      </label> -->
      <label translate>Type</label>
      <select
        avInput
        name="type"
        #typeRef="ngModel"
        [(ngModel)]="item.type"
        (ngModelChange)="update()"
      >
        <option
          *ngFor="let o of types"
          [ngValue]="o.value"
          [disabled]="o.disabled"
        >
          {{ o.label }}
        </option>
      </select>
      <div
        translate
        class="form-error"
        *ngIf="typeRef.touched && typeRef.hasError('required')"
      >
        Required
      </div>
    </div>
  </ng-container>

  <div class="form-item">
    <label>&nbsp;</label>
    <button
      [attr.aria-label]="'Delete pair' | translate"
      avButton
      ofType="icon"
      size="xs"
      color="danger"
      data-tid="metadata/item/remove"
      (click)="remove()"
    >
      <av-icon name="trash"></av-icon>
    </button>
  </div>
</form>
