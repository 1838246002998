import { auviousLicense } from "../../../../app/app.enums";
import {
  ConversationDestinationEnum,
  ConversationOriginEnum,
  AppointmentChannelEnum,
  AgentFeatureEnum,
  IUser,
  IUserDetails,
  IPagedResponse,
  IApplicationConfig,
  CLAIM_OPEN_ID,
  IOpenIDConnectApplicationConfig,
} from "../../../../core-ui";
import { IIntegrationStrategy } from "../../../../core-ui/models/strategies";
import { IIntegration } from "../../IIntegration";

export class VerintIntegrationStrategy implements IIntegrationStrategy {
  installIntegration(
    integration: IIntegration,
    destination?: ConversationDestinationEnum
  ): Promise<void> {
    return undefined;
  }
  isInstalled(integration: IIntegration): boolean {
    return true;
  }
  getIntegrations(): Promise<IIntegration[]> {
    return Promise.resolve([]);
  }
  getIntegrationId(): string {
    return undefined;
  }
  deleteIntegration(id: string): Promise<void> {
    return undefined;
  }
  supportsInteractionDestination(
    destination: ConversationDestinationEnum
  ): boolean {
    return [ConversationDestinationEnum.STANDALONE].includes(destination);
  }
  supportsInteractionOrigin(origin: ConversationOriginEnum): boolean {
    return [
      ConversationOriginEnum.CHAT,
      ConversationOriginEnum.WIDGET,
    ].includes(origin);
  }
  supportsAppointmentChannel(channel: AppointmentChannelEnum): boolean {
    return false;
  }
  supportsAgentFeature(feature: AgentFeatureEnum): boolean {
    // todo: check if displayCapture is supported in iFrame
    return false;
  }
  getIntegrationURL(destination?: ConversationDestinationEnum): string {
    return undefined;
  }
  supportsMemberRoles(): boolean {
    return true;
  }
  getIntegrationIdentifier(): string {
    return undefined;
  }
  getIntegrationUserId(user: IUser): string {
    return user.getClaim(CLAIM_OPEN_ID);
  }
  getOauthClientId(environment?: string): string {
    return undefined;
  }
  getMember(id: string): Promise<IUserDetails> {
    // todo: implement
    return undefined;
  }
  getMembers(
    page: number,
    size: number
  ): Promise<IPagedResponse<IUserDetails>> {
    // todo: implement
    return undefined;
  }
  hasLicense(key: auviousLicense): boolean {
    // todo: implement
    return true;
  }
  getExportableConfig(config: IApplicationConfig): IApplicationConfig {
    try {
      const deepCopy = JSON.stringify(config);
      const cf: IOpenIDConnectApplicationConfig = JSON.parse(deepCopy);

      // remove params that are application specific
      delete cf.openidParameters;
      return cf;
    } catch (ex) {
      return config;
    }
  }
}
