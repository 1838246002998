import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
} from "@angular/core";
import { IMetadata, ISelectOption } from "../../../../../core-ui";
import { NgForm } from "@angular/forms";
import { markFormFields } from "../../../../../app/app.utils";

@Component({
  selector: "av-metadata-builder-pair",
  templateUrl: "./pair.component.html",
  styleUrls: ["./pair.component.scss"],
})
export class MetadataBuilderPairComponent implements OnInit {
  @Input() item: IMetadata<any>;
  @Input() types: ISelectOption[];

  @Output() updated = new EventEmitter<IMetadata<any>>();
  @Output() removed = new EventEmitter<IMetadata<any>>();

  @ViewChild("f") formRef: NgForm;

  hasType = false;

  ngOnInit(): void {
    this.hasType = this.types?.length > 0;
  }

  update() {
    this.updated.emit(this.item);
  }

  remove() {
    this.removed.emit(this.item);
  }

  get valid() {
    // markFormFields(this.formRef.form);
    return this.formRef.valid;
  }
}
